import {
  GET_CLIENTS_DETAIL,
  CLIENT_ADD,
  CLEAR_CLIENTS,
  LOAD_CLIENTS,
  CLIENT_DELETE,
  UPDATE_CLIENT,
  GET_CLIENTS_DETAIL_EVENT,
  GET_CLIENT_EVENTS,
  CLEAR_CLIENTS_DETAIL_EVENT,
  CLIENT_LIST_TO_PHARSE,
  NEXT_CLIENT_LIST_TO_PHARSE,
  CLEAR_CLIENT_LIST_TO_PHARSE,
  FIND_DOUBLE_CLIENTS,
  CLEAR_DOUBLE_CLIENTS,
  CLIENTS_SORT_BY_DATE,
  CLIENTS_SORT_ALFABETICAL,
  GET_CLIENTS_BY_VISIT_DATE,
  CLIENTS_LOADING,
} from "../actions/types.js";

const sort_events_reverse = (tab) => {
  console.log(tab);
  const tablica = tab.sort(function (obj1, obj2) {
    // Ascending: first age less than the previous
    if (obj1.client && obj2.client) {
      const objk = new Date(...[...obj1.client.data_wizyty.split("-")]);
      const objc = new Date(...[...obj2.client.data_wizyty.split("-")]);
      return objk - objc;
    } else if (obj1.client) {
      return -1;
    } else {
      return 1;
    }
  });
  console.log(tablica);
  return tablica;
};

function sort_alf(tablica) {
  return tablica.sort(function (a, b) {
    return a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase());
  });
}

const initialState = {
  clients: [],
  isLoading: false,
  doubleClients: [],
  pharseClientsList: [],
  clientsList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  clientDetail: null,
  clientDetailEvent: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_CLIENTS:
      return {
        ...state,
        clientsList: {results: action.payload},
      };
    case CLIENTS_SORT_BY_DATE:
      return {
        ...state,
        clientsList: {
          count: state.clientsList.count,
          next: state.clientsList.next,
          previous: state.clientsList.previous,
          results: sort_events_reverse(state.clientsList.results),
        },
      };
    case CLIENTS_SORT_ALFABETICAL:
      return {
        ...state,
        clientsList: {
          count: state.clientsList.count,
          next: state.clientsList.next,
          previous: state.clientsList.previous,
          results: sort_alf(state.clientsList.results),
        },
      };
    case GET_CLIENTS_DETAIL:
      return {
        ...state,
        clientDetail: action.payload,
      };
    case CLEAR_CLIENTS_DETAIL_EVENT:
      return {
        ...state,
        clientDetailEvent: null,
      };
    case GET_CLIENTS_DETAIL_EVENT:
      return {
        ...state,
        clientDetailEvent: action.payload,
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        clientDetail: action.payload,
      };
    case CLIENT_DELETE:
      const dwno = state.clientsList.results.filter(
        (event) => event.id !== parseInt(action.payload)
      );
      console.log(dwno);
      return {
        ...state,
        clientDetail: null,
        doubleClients: state.doubleClients.filter(
          (event) => event.id !== parseInt(action.payload)
        ),
        clientsList: {
          count: state.clientsList.count,
          next: state.clientsList.next,
          previous: state.clientsList.previous,
          results: [...dwno],
        },
      };
    case FIND_DOUBLE_CLIENTS:
      return {
        ...state,
        doubleClients: action.payload,
      };
    case CLEAR_DOUBLE_CLIENTS:
      return {
        ...state,
        doubleClients: [],
      };
    case CLIENT_LIST_TO_PHARSE:
      return {
        ...state,
        pharseClientsList: action.payload,
      };
    case NEXT_CLIENT_LIST_TO_PHARSE:
      return {
        ...state,
        pharseClientsList: [...state.pharseClientsList.slice(1)],
      };
    case CLEAR_CLIENT_LIST_TO_PHARSE:
      return {
        ...state,
        pharseClientsList: [],
      };
    case CLEAR_CLIENTS:
      return {
        ...state,
        clientsList: {
          count: 0,
          next: null,
          previous: null,
          results: [],
        },
      };
    case CLIENTS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CLIENTS_BY_VISIT_DATE:
      return {
        ...state,
        clients: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
